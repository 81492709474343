<template>
  <div class="main">
    <Head />
    <div class="big_logo">
      <img
        src="../../assets/image/mobile/index/games1.jpg"
        alt=""
        class="bgi"
      >
    </div>
    <div class="help-content">
      <div class="help-box">
        <ul>
          <li>
            <div class="bottom problem">
              <div class="p_content">
                <p class="p_title">联系我们</p>
                <p class="p_icon">联系方式：</p>
                <p class="p_icon">Email：support@gg.com</p>
                <p class="p_icon">电话：400-9031-158</p>
                <p class="p_icon">
                  公司地址：中国（上海）自由贸易试验区芳春路400号1幢3层
                </p>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
export default {
  name: 'Contact',
  components: { Head, Foot }
}
</script>

<style scoped>
.big_logo{
   padding-top:3.575rem;
}
.big_logo .bgi {
  width: 100%;
}
.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-content .help-box {
  margin: 1.25rem 0;
  overflow: hidden;
  width: 35.625rem;
  background-color: #fff;
  padding: 0.8125rem;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  font-size: 0.5625rem;
  margin-bottom: 0.625rem;
}
.help-content .help-box li .problem {
  padding: 0 1.25rem 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.help-content .help-box li .bottom {
  border-top: 0.0325rem solid #eee;
  display: block;
}
.p_content .p_title {
  font-size: 0.5587rem;
  font-weight: 700;
  line-height: 1.5rem;
}
.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2rem;
  font-size: 0.4375rem;
}
</style>
